.list-row:hover {
  cursor: pointer;
  background-color: #f5f5f585;
}

.leader-icon {
  width: 56px;
  height: 56px;
  border-radius: 80px;
  margin-right: 13px;
}

.leader-type {
  font-size: 12px;
  font-weight: 300;
}

td .follow-btn {
  width: 77px;
  height: 40px;
  margin-left: 10px;
}

th {
  font-size: 14px;
  color: #81858c;
  font-weight: 400;
}

.leader-table-container th,
.leader-table-container td {
  padding: 15px 40px;
}

.leader-table-container .leader-name {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
}

.LeaderBoardListItem th:first-child {
  padding-right: 190px;
}

.LeaderBoardListItem tr {
  border-bottom: 2px solid black;
}

tr .roi {
  font-size: 16px;
  font-weight: 500;
}

tr .pnl {
  color: #10b866;
  font-size: 16px;
  font-weight: 500;
}

.circle {
  display: inline-block;
  vertical-align: top;
}
