.BotManagementCard {
  min-width: 580px;
  height: 315px;
  padding: 25px 30px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  margin: 10px;
}

.BotManagementCard .bot-type {
  color: #10b866;
  border: 1px solid #10b866;
  padding: 5px 10px;
  border-radius: 8px;
  margin-right: 10px;
}

.BotManagementCard .account-type {
  color: #f3ba2f;
  border: 1px solid #f3ba2f;
  padding: 5px 10px;
  border-radius: 8px;
}

.BotManagementCard .user-account-icon {
  width: 54px;
  height: 54px;
  margin-right: 13px;
  margin-bottom: 30px;
}

.BotManagementCard .col:first-child {
  min-width: 290px;
}

.bot-option {
  font-size: 16px;
  color: #000000;
  margin-bottom: 18px;
}

.bot-option span {
  color: #81858c;
}

.bot-option.green {
  color: #10b866;
}

.bot-option.red {
  color: #ff0000;
}

.bot-button-container {
  margin-top: 5px;
}

.bot-button-container div {
  background-color: #feeb61;
  border: 4px solid #f1df58;
  border-radius: 50%;
  margin-right: 15px;
  padding: 8px 13px;
}

.bot-button-container div:hover {
  background-color: #fef961;
}

.bot-button-container div:active,
.bot-button-container div.disabled {
  background-color: #fafafa;
  border: 4px solid #dedede;
}

.bot-button-container .play-icon {
  width: 14px;
  height: 20px;
  margin-left: 2px;
}

.bot-button-container .stop-icon,
.bot-button-container .edit-icon,
.bot-button-container .delete-icon,
.bot-button-container .statistic-icon {
  width: 15px;
  height: 15px;
}

.start-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  width: 150px;
  height: 40px;
  margin: 0 auto;
  color: #080808;
}

.start-btn:disabled {
  border: 1px solid lightgrey;
  background-color: lightgrey;
}

.copy-header,
.approve-header {
  margin: 0;
  color: #262626;
  font-size: 20px;
}

.approve-text {
  margin: 20px;
  text-align: center;
  font-weight: 250;
  font-size: 18px;
}

.run-bot-modal .modal-content {
  width: 900px;
  right: 40%;
  margin-top: 10%;
}

.run-bot-modal h5 {
  margin-top: 30px;
}

.run-bot-modal th,
.run-bot-modal td,
.run-bot-modal tr .direction {
  width: 0;
  padding: 10px 40px;
  font-size: 14px !important;
}

.run-bot-modal .insufient-funds-warning {
  color: red;
  text-align: center;
}

.table-container {
  overflow-y: scroll;
  height: 300px;
}

.radio-buttons-wrapper {
  width: 900px;
  margin: 10px 30px;
}

.radio-buttons-wrapper span.green {
  color: #10b866;
}

.radio-buttons-wrapper span.red {
  color: #ff0000;
}

.approve-btn.green {
  color: white;
  background-color: #10b866;
  border: 1px solid #10b866;
}
