.apikey-container {
  padding: 10px 10px;
  margin: 20px 20px;
  border: gray 1px solid;
}

.admin-table-container .admin-table th,
.admin-table-container .admin-table td {
  padding: 15px 10px;
  font-size: 14px;
  text-align: center;
}

tr .roi {
  font-size: 16px;
  font-weight: 500;
}

tr .pnl {
  color: #10b866;
  font-size: 16px;
  font-weight: 500;
}

tr .red {
  color: red;
}

tr .green {
  color: #10b866;
}

.apikey-container {
  padding: 10px 10px;
  margin: 20px 20px;
  border: gray 1px solid;
}
