.modal-follow-container .modal-dialog {
  max-width: 1000px;
}

.modal-follow-container .BotConfigurationForm {
  margin: 0 auto;
}

.modal-follow-container .BotConfigurationForm .botconfig-container-left {
  margin-top: 0px;
  width: 50%;
  margin-right: 40px;
}
.modal-follow-container .BotConfigurationForm .botconfig-container-right {
  margin-top: 0px;
  width: 50%;
  margin-right: 0px;
}

.modal-follow-container .account-choice select {
  appearance: none;
  height: 40px;
  border-radius: 8px;
  padding-left: 15px;
  border: 1px solid #f1f1f1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAHCAYAAAA4R3wZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgBjdDBDYAwCAXQ/lGAQVylTqaruAiMgsWbSWnhBISXkA8R6e5+qOrZCsXMF4DnG4jojkUFxe1vucNTtMNLlOEMIcMjAI9+BAcz661agVfvvUxdIxLXZfbLAAAAAElFTkSuQmCC)
    no-repeat 450px 16px;
  background-color: #f1f1f1;
  -moz-appearance: none;
}

.modal-follow-container .save-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  font-size: 18px;
  width: 208px;
  height: 50px;
  margin-left: 8px;
  color: #080808;
}

.modal-follow-container .save-btn:disabled {
  border: 1px solid lightgrey;
  background-color: lightgrey;
  border-radius: 4px;
  font-size: 18px;
  width: 208px;
  height: 50px;
  margin-left: 8px;
  color: #080808;
}
