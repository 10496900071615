.LeaderAllData table {
  width: 100%;
  height: auto;
  transition: height 2s;
}

.LeaderAllData table td:first-child {
  margin-right: 135px;
}

.LeaderAllData table th:last-child,
.LeaderAllData table td:last-child {
  text-align: center;
}

.LeaderAllData table td:last-child {
  padding-left: 59px;
}

.negative-value {
  color: #ff0000;
}

.positive-value {
  color: #10b866;
}
