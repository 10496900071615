.leader-slider {
  background: #fff;
}
.leader-slider .swiper-button-prev,
.leader-slider .swiper-button-next {
  display: none;
}
.slide-inner {
  margin-top: 130px;
  max-width: 619px;
}
.slide-1 {
  background: url(../../images/leader-monet-slide-1.jpg) no-repeat left top / auto 100%,
    url(../../images/leader-slide-1.jpg) no-repeat right top / auto 100%;
}
.slide-inner p {
  font-size: 72px;
  line-height: 85px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 30px;
}
.slide-inner span {
  white-space: nowrap;
}
.slide-inner .binance-powered {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #262626;
}
.slide-inner .binance-logo {
  margin-left: 16px;
}
.main-container .bg-binance {
  background-image: url('../../images/bg-binance-bottom.svg');
  width: 240px;
  height: 240px;
  background-repeat: no-repeat;
  position: absolute;
  top: 362px;
  left: 508px;
  z-index: -1;
  opacity: 0.99;
}

@media screen and (max-width: 1415px) {
  .slide-1 {
    background: url(../../images/leader-monet-slide-1.jpg) no-repeat left top / auto 100%,
      url(../../images/leader-slide-1.jpg) no-repeat center top / auto 100%;
  }
  .slide-inner {
    margin-left: 110px;
  }
}

@media screen and (max-width: 1180px) {
  .slide-inner {
    max-width: 400px;
    margin-left: 90px;
  }
  .slide-inner p {
    font-size: 46px;
    line-height: 1.24;
  }
  .leader-slider .swiper {
    height: 510px;
  }
}

@media screen and (max-width: 800px) {
  .main-container {
    margin: 45px 0 0px 34px;
  }
  .slide-1 {
    background: url(../../images/leader-slide-1-mob.jpg) no-repeat center bottom / cover;
  }
  .slide-inner {
    max-width: 260px;
    margin-top: 40px;
    margin-left: 0;
  }
  .slide-inner p {
    font-size: 35px;
    margin-bottom: 16px;
  }
  .slide-inner .binance-powered {
    font-size: 14px;
  }
  .leader-slider .swiper {
    height: 460px;
  }
  .slide-inner .binance-logo {
    display: block;
    margin-left: 0;
    margin-top: 2px;
    max-width: 130px;
  }
  .slide-inner span {
    white-space: initial;
  }
}
