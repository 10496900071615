.ConfirmEmail {
  margin-top: 180px;
  margin-bottom: 200px;
}
/* 
.code-container {
  text-align: center;
}

.code-input {
  margin-right: 0px;
  padding: 0px;
  width: 160px;
  height: 50px;
  font-size: 25px;
  text-align: center;
} */

.ConfirmEmail .submit-code-btn {
  width: 250px;
}

.ReactInputVerificationCode__container {
  margin: 0 auto;
  margin-top: 40px;
}

.confirm-email-button-container {
  margin: 0 auto;
}

.submit-code-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  padding: 10px 10px;
  display: block;
  margin: 35px 10px;
}
