.Sidebar {
  width: 328px;
  height: 100%;
  padding: 32px 10px 0px 24px;
  color: #262626;
}

.sidebar-user-info {
  margin-right: 40px;
}

.sidebar-header p {
  margin-bottom: 0px;
}

.sidebar-header p:first-child {
  font-size: 20px;
  margin-bottom: 8px;
}

.sidebar-header p:last-child {
  color: #81858c;
  font-size: 14px;
}

.sidebar-icon {
  background-color: #f1f1f1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.sidebar-list {
  text-decoration: none;
  list-style: none;
  padding: 0px;
}

.Sidebar nav {
  margin-top: 32px;
  border-bottom: 1px solid #dcdfe4;
}

.sidebar-list li {
  margin-bottom: 32px;
}

.sidebar-list li a {
  text-decoration: none;
  color: #262626;
  font-size: 16px;
  margin-left: 12px;
}

.logout-section {
  margin-top: 32px;
  margin-bottom: 32px;
}

.logout-section:hover {
  cursor: pointer;
  background-color: #dbdada85;
}

.logout-section a {
  text-decoration: none;
  color: #262626;
}

.logout-section img {
  margin-right: 15px;
}
