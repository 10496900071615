.bg-image-bottom {
  background-image: url("../../images/bg-leaderboard-bottom.png");
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 1295px;
  right: 398px;
  z-index: -1;
  opacity: 0.99;
}

.bg-image-right {
  background-image: url("../../images/bg-leaderboard-right.png");
  width: 200px;
  height: 200px;
  color: #f3f3f3;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 272px;
  right: 48px;
  z-index: -1;
  opacity: 0.99;
}

.bg-image-left {
  background-image: url("../../images/bg-leaderboard-left.png");
  width: 200px;
  height: 200px;
  color: #f3f3f3;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 400px;
  left: 60px;
  z-index: -1;
  opacity: 0.99;
}
