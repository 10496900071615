.AccountWallet {
  width: 100%;
  border-left: 1px solid #dcdfe4;
}

.user-info-container {
  background-color: #fafafa;
  padding: 34px 0px 30px 44px;
}

.user-info-container .user-account-icon {
  width: 100px;
  height: 100px;
  margin-right: 37px;
}

.user-info-container .wallet-username {
  font-size: 20px;
  font-weight: 700;
  color: #2e2e2e;
}

.user-info-container .userId {
  margin-right: 30px;
}

.user-info-container .userId p:first-child,
.user-info-container .last-login p:first-child {
  font-size: 16px;
  color: #81858c;
  font-weight: 500;
  margin-bottom: 6px;
}

.user-info-container .userId p:last-child,
.user-info-container .last-login p:last-child {
  font-size: 16px;
  color: #2e2e2e;
  font-weight: 500;
  margin-bottom: 0px;
}

.user-info-container .user-info-footer {
  margin-top: 55px;
}

.user-info-footer .more-info-icon {
  margin-left: 7px;
  width: 16.67px;
  height: 16.67px;
}

.user-info-footer .estimated-balance {
  margin-right: 85px;
}

.user-info-footer .estimated-balance p:first-child,
.user-info-footer .referrals p:first-child {
  font-size: 20px;
  line-height: 23px;
  color: #262626;
  margin-bottom: 8px;
}

.user-info-footer .estimated-balance p:nth-child(2),
.user-info-footer .referrals p:nth-child(2) {
  font-size: 34px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 8px;
}

.user-info-footer .estimated-balance p:last-child,
.user-info-footer .referrals p:last-child {
  font-size: 16px;
  color: #10b866;
  font-weight: 500;
  margin-bottom: 0px;
}

.user-info-container .wallet-buttons {
  margin-top: 29px;
}

.grey-btn {
  color: #080808;
  background-color: #eaecef;
  border-radius: 4px;
  padding: 10px 25px;
  border: 1px solid #eaecef;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
}

.yellow-btn {
  background-color: #feeb61;
  border: 1px solid #feeb61;
  margin-right: 20px;
  border-radius: 4px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  color: #080808;
}

.user-info-container .account-bg {
  background-image: url("../../images/wallet-bg-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 650px;
  height: 600px;
  top: 35px;
  right: 20px;
  opacity: 0.99;
}

.user-statistics-container {
  padding: 34px 44px 30px 44px;
  background-color: #fafafa;
}

.user-statistics-container .user-statistics-table {
  border-radius: 8px;
  color: #ffffff;
}

.user-statistics-table th {
  padding: 42px 0px;
  text-align: center;
  font-size: 14px;
  color: #898d93;
  border-bottom: 1px solid #dde0e5;
  white-space: nowrap;
}

.user-statistics-table th:first-child {
  padding-left: 18px;
}

.user-statistics-table td {
  padding: 24px 0px;
  text-align: center;
  color: #202020;
  font-size: 14px;
  font-weight: 500;
}

.user-statistics-table td:first-child {
  padding-left: 18px;
}

.user-statistics-table .statistic-table-btn {
  background-color: #feeb61;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #feeb61;
  font-size: 12px;
}

.user-statistics-table .circled-green {
  color: #10b866;
  border: 1px solid #10b866;
  padding: 5px 10px;
  border-radius: 8px;
}

.user-statistics-table .circled-red {
  color: #fe616a;
  border: 1px solid #fe616a;
  padding: 5px 10px;
  border-radius: 8px;
}

.user-statistics-container .not-found-container {
  margin: 54px auto 54px auto;
  text-align: center;
}

.user-statistics-container .not-found-container .not-found-icon {
  margin-bottom: 36px;
  width: 157px;
  height: 148px;
}

.user-statistics-container .not-found-container p {
  color: #81858c;
  font-size: 19px;
  font-weight: 500;
  margin: 0px;
}
