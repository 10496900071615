.Payment {
  padding: 64px 200px 60px;
  color: #262626;
  background-color: #f5f5f585;
}

.payment-info-container h1 {
  font-size: 72px;
  font-weight: 400;
}

.payment-section {
  margin-top: 32px;
}

.payment-section h6 {
  margin-bottom: 24px;
  font-size: 20px;
}

.payment-method-input {
  width: 100%;
  border: 1px solid #dde0e5;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

.payment-method-input .not-available {
  color: grey;
  font-size: 12px;
  margin-top: -26px;
}

.payment-method-input:last-child {
  margin-bottom: 0px;
}

.payment-method-input label {
  margin: 24px auto;
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.payment-method-input img {
  float: left;
  margin-left: 20px;
}

.payment-method-input .radio {
  width: 24px;
  height: 24px;
  accent-color: #262626;
  margin-top: -1px;
  vertical-align: middle;
  float: right;
  margin-right: 20px;
}

.credit-card-info {
  margin: 8px 0px 24px 0px;
}

.credit-card-info .credit-number-input {
  margin-bottom: 24px;
  width: 100%;
}

.credit-card-info label,
.personal-data-input-container label,
.account-address-input-container label {
  font-size: 14px;
  color: #81858c;
}

.credit-card-info label input {
  padding: 12px 0px 12px 16px;
  margin-top: 8px;
  display: block;
  width: 100%;
}

.personal-data-input-container label,
.account-address-input-container label {
  width: 100%;
}

.personal-data-input-container .personal-email {
  margin-bottom: 24px;
}

.personal-data-input-container input,
.account-address-input-container input {
  display: block;
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #dde0e5;
  margin-top: 8px;
}

.account-address-input-container .street-input {
  margin-top: 24px;
  margin-bottom: 24px;
}

.payment-period-input {
  width: 100%;
  border: 1px solid #dde0e5;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 16px;
  color: #000000;
}

.payment-period-input .radio {
  width: 24px;
  height: 24px;
  accent-color: #262626;
  margin-top: -1px;
  vertical-align: middle;
  margin-right: 20px;
}

.payment-period-input .period {
  margin-left: 20px;
  font-size: 14px;
}

.payment-period-input .period-price {
  margin-right: 24px;
  font-size: 18px;
}

.payment-period-input .economy {
  font-size: 12px;
  color: #81858c;
  margin-left: 20px;
}

.payment-period-input:last-child {
  margin-bottom: 0px;
}

.payment-period-input label {
  margin: 24px auto;
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.hidden-form {
  display: none;
}

.payment-agreement {
  margin-top: 16px;
  color: #000000;
  font-size: 12px;
  margin-bottom: 24px;
}

.payment-agreement input {
  vertical-align: middle;
  margin-right: 8px;
}

.payment-agreement a {
  color: #000000;
}

.payment-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  padding: 11px 0px;
  width: 100%;
}
.selected-tarrif-container .payment-option-card {
  width: 384px;
  height: 414px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: white;
  padding: 32px 24px;
  margin-left: auto;
}

.payment-option-card .option-name {
  font-size: 20px;
  font-weight: 400px;
  line-height: 23px;
  margin-bottom: 8px;
}

.payment-option-card .option-period {
  color: #81858c;
  font-size: 18px;
  margin-bottom: 8px;
}

.payment-option-card .next-payment {
  margin-bottom: 8px;
  color: #81858c;
  font-size: 12px;
}

.payment-option-card .option-price {
  font-size: 16px;
  margin-bottom: 20px;
}

.payment-option-card .option-price span {
  font-size: 32px;
}

.payment-option-card ul {
  margin-top: 32px;
  list-style: none;
  padding: 0px;
}

.payment-option-card ul li {
  font-size: 16px;
  line-height: 25px;
}

.payment-option-card ul li,
.payment-option-card ul li {
  margin-bottom: 18px;
}

.payment-option-card ul img {
  margin-right: 12px;
}

.tarrif-total-price-container {
  margin-top: 24px;
}

.tarrif-total-price-container span:first-child {
  font-size: 12px;
  color: #81858c;
}

.tarrif-total-price-container span:last-child {
  font-size: 32px;
  color: #262626;
}
