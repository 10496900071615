/* Filter List */
.filterList-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
  width: 100%;
  position: relative;
}
.filterList-block:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #dde0e5;
}
.filterList-block .ListOrTableDisplay {
  margin: 0;
}
.filterList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.filterList li {
  position: relative;
  color: #81858c;
  margin-right: 30px;
  cursor: pointer;
  padding: 16px 0;
  transition: 0.3s;
}
.filterList li:after {
  content: '';
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #feeb61;
  transition: 0.3s;
  z-index: 1;
}
.filterList li.is-active {
  font-weight: 600;
  color: #252420;
}
.filterList li.is-active:after {
  opacity: 1;
}
.filterList li:hover {
  color: #c5ad01;
}
