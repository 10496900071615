.approve-modal .modal-dialog {
  top: 130px;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.approve-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  width: 47%;
  height: 40px;
  margin: 5px;
  color: #080808;
}

.approve-btn.delete {
  border: 1px solid rgb(255, 68, 68);
  background-color: rgb(255, 68, 68);
  color: white;
  font-weight: 500;
}
