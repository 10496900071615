.Registration {
  color: #262626;
  margin-top: 180px;
  margin-bottom: 200px;
}

.Registration .content-container {
  border: 1px solid #dde0e5;
  border-radius: 4px;
  padding: 40px 35px;
}

.Registration h4 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 15px;
}

.Registration .login-btn {
  border: 1px solid #feeb61;
  background-color: #feeb61;
  border-radius: 4px;
  padding: 11px 0px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group .form-input {
  border: 1px solid #dde0e5;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
}

.form-group label {
  margin-bottom: 8px;
  font-size: 16px;
  color: #81858c;
}

.form-bottom {
  margin-top: 13px;
  font-size: 12px;
  color: #000000;
}

.form-bottom a {
  color: #000000;
}

.form-group .conf-agreement {
  font-size: 12px;
  color: #000000;
}

.conf-agreement a {
  color: #000000;
}

article ol {
  counter-reset: item;
  padding: 0px;
}

article ol li {
  display: block;
}

article ol li::before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.consent-modal .modal-dialog {
  max-width: 800px;
}

.consent-modal .modal-body {
  padding: 25px;
}
