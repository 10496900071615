.ReferralProgram {
  color: #262626;
  width: 100%;
  border-left: 1px solid #dcdfe4;
}

.ReferralProgram h2 {
  font-size: 30px;
}

.ReferralProgram .referral-header-container {
  padding: 34px 100px;
  background-color: #fcfcfc;
}

.referral-header-left p:first-child {
  font-size: 52px;
}

.referral-header-left p:first-child {
  font-size: 52px;
}

.referral-header-left p:last-child {
  font-size: 16px;
}

.referral-header-right {
  width: 430px;
  padding: 25px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 1;
}

.referral-header-right .referral-info-icon {
  width: 16px;
  height: 19px;
  vertical-align: top;
  margin-left: 10px;
}

.toolbar-info-section .more-info-icon {
  margin-left: 7px;
  width: 16.67px;
  height: 16.67px;
  margin-bottom: 3px;
}

.ReferralProgram .clip-icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.referral-header-right div:first-child {
  font-size: 17px;
  margin-bottom: 20px;
}

.referral-header-right div:first-child a {
  color: #c49939;
}

.referral-header-right .referral-benefit-section {
  margin-bottom: 15px;
  padding: 7px 0px;
  background-color: #fdf6db;
  border-radius: 10px;
}

.referral-header-right .referral-benefit-section p:first-child,
.referral-header-right .referral-id-section p:first-child,
.referral-header-right .referral-link-section p:first-child {
  font-size: 15px;
}

.referral-header-right .referral-benefit-section p:last-child {
  font-size: 30px;
  font-weight: 700;
}

.referral-header-right .referral-id-section,
.referral-header-right .referral-link-section {
  margin-top: 15px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 14px 18px;
}

.referral-header-right .referral-link-section {
  margin-top: 0px;
}

.referral-header-right .referral-id-section p:last-child {
  font-weight: 700;
  font-size: 15px;
}

.referral-header-right .referral-link-section a {
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  color: #262626;
}

.referral-bg {
  background-image: url("../../images/wallet-bg-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 655px;
  height: 745px;
  top: 26px;
  right: 439px;
  opacity: 0.99;
}

.referral-main-container {
  padding: 50px 100px;
  background-color: #f5f5f585;
}

.referral-main-container .toolbar-list {
  list-style: none;
  color: #81858c;
  margin-top: 23px;
  margin-bottom: 15px;
  padding-left: 0px;
  z-index: 1;
  position: relative;
}

.referral-main-container .toolbar-list li {
  cursor: pointer;
}

.referral-main-container .toolbar-list .grey {
  margin-right: 24px;
  font-size: 15px;
  border-bottom: 2px solid #dde0e5;
}

.yellow {
  border-bottom: 3px solid #feeb61;
  margin-right: 24px;
  font-size: 15px;
  color: #262626;
}

.toolbar-info-container {
  margin-top: 24px;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
}

.toolbar-info-container .toolbar-info-section {
  margin-right: 80px;
}

.toolbar-info-container .toolbar-info-section p:first-child {
  font-size: 17px;
  margin-bottom: 8px;
  padding: 0px;
}

.toolbar-info-container .toolbar-info-section p:nth-child(2) {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;
  margin-bottom: 8px;
  padding: 0px;
}
/* 
.toolbar-info-container .toolbar-info-section p:last-child {
  font-size: 14px;
  font-weight: 500;
  color: #10b866;
  margin-bottom: 0px;
  padding: 0px;
} */

.referral-main-container .attention-paragraph {
  font-size: 12px;
  color: #81858c;
  margin-bottom: 50px;
}

.referral-main-container .referral-table-container ul {
  list-style: none;
  color: #81858c;
  margin-top: 23px;
  margin-bottom: 15px;
  padding-left: 0px;
}

.referral-table-container ul li {
  margin-right: 24px;
  font-size: 15px;
}

.referral-table-container .referral-table {
  margin-top: 24px;
  background-color: inherit;
}

.referral-table-container .referral-table th {
  color: #81858c;
  font-size: 14px;
  padding: 26px 20px 26px 20px;
}

.ReferralProgram .referral-footer {
  background-color: #29261e;
  padding: 72px 0px;
}

.referral-footer p {
  color: #ffffff;
}

.referral-footer p:first-child {
  font-size: 32px;
  margin-bottom: 20px;
}

.referral-footer p:nth-child(2) {
  font-size: 18px;
  margin-bottom: 30px;
}

.referral-footer .referral-btn {
  width: 420px;
}

.referral-table td {
  padding: 16px 20px 16px 20px;
}

.referral-table td .leader-icon {
  width: 48px;
  height: 48px;
}

.referral-table td .leader-name {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}

.referral-table td .leader-type {
  color: #81858c;
}

.referral-table tbody tr {
  border-bottom: 1px solid #dde0e5;
  border-radius: 8px;
  background-color: #ffffff;
}
