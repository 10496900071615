table {
  background: white;
  border-radius: 8px;
  margin: 0 auto;
}

.leader-position-table th,
.leader-position-table .leader-position-item td,
.leader-position-table .leader-position-item tr .direction {
  text-align: center;
  padding: 15px 20px;
}

.leader-position-total-container {
  padding: 30px;
}

.leader-position-total-container .green {
  font-size: 22px;
  color: #10b866;
}

.leader-position-total-container .red {
  font-size: 22px;
  color: red;
}
